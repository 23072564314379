<template>
    <div class="text-left py-3 px-4 px-lg-5">
        <h1>About Us</h1>
        <br>
        StatHub.gg is a third party application / platform designed for helping players improve their skills and explore more variety in games. We are a registered product under Riot Games Inc. and all its owned products including: League of Legends, Legend of Runeterra, Teamfight Tactics, Valorant.
        <hr>
        <h3>Goals</h3>
        <ul>
            <li>Provide valuable, accurate, and updated content for players.</li>
            <li>A high quality app in terms of UI and performance.</li>
            <li>Easy player access thru multiple platforms (Mainly as Progressive Web App).</li>
        </ul>
        <br>
        <h3>Contents</h3>
        <ul>
            <li>Real time game profile look up, analize match history data.</li>
            <li>Game factors statistics, focused on all elo.</li>
            <li>Leaderboard with direct access to profile, learn from high elo players.</li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class About extends Vue {

    created(): void {
        this.$meta.use({
            title: 'About Us | StatHub.gg',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg - About Us.'
            }
        })
    }

}
</script>
